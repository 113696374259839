/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const keywords = 'graphic design, design studio, award winning design firm, best agency New York Zurich Berlin, advertising, werbung, werbeagentur, advertising agency, branding agency, digital agency, cornelia koch, connie koch, denise sommer, branding, corporate identity, logo design, boutique design firm, design agentur, typography, branding, social non profits, ngo branding, institutional branding, non-profit branding, catalogue design, art direction, brochure design, web design, print graphics, ahoy design';
const siteMetadata = {
  title: `Ahoy Studios`,
  siteUrl: `https://www.ahoystudios.com`,
  description: `ahoy, hatched in NYC in 2000, is an internationally operating, women-owned creative branding agency. We collaborate with visionaries around the world to design a happy, sustainable tomorrow.`,
  author: `arnespremberg`,
};

function SEO({ description, lang, meta, title, thumb }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `AHOY - ${title}` : "AHOY"}
      // titleTemplate={`%s | AHOY`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: thumb && `og:image`,
          content: thumb,
        },
      ].concat(meta)}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
